<template>
  <v-app>
    <Header></Header>
    <v-main>
      <div class="container pc" v-if="!isMobile">
        <div class="title">
          <h2>
            為一 Art Space是一间以日本浮世绘版画为主题，融汇了浮世绘展厅、日本工艺美术精品和咖啡业态的日本美学展示空间。
          </h2>
          <h2 class="jp">
            Weiyi Art Space is a Japanese aesthetic exhibition space with the theme of Japanese Ukiyo-e prints, which integrates the Ukiyo-e exhibition, Japanese arts , crafts and a coffee corner.
          </h2>
          <v-divider></v-divider>
        </div>
        <div class="exhibitions">
          <TitleBar title="Exhibitions丨展览" href="exhibitions"></TitleBar>
          <div class="card">
            <img class="exhibitions-img" :src="disPaly.imgUrl" alt="#" @click="searchExById(disPaly.id)" />
            <div class="content">
              <!-- <p>
                欢迎来到Egenolf画廊日本版画，自1975年以来一直提供优质的浮世绘和shin
                hanga。现在位于南加州，我们的 18 至 20
                世纪艺术品以美观、良好状态为策展，并保证原创且如描述。我们最近从日本回来，带来了一批了不起的新收购;作品每天发布，我们通过联邦快递以牢不可破的包装发货。
              </p> -->
              <h2>{{ disPaly.title }}</h2>
              <!-- <div class="float"></div> -->
              <div class="content-p">{{ disPaly.content }}</div>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="exhibitions gallery">
          <TitleBar title="Gallery丨画廊" href="gallery"></TitleBar>
          <div class="card">
            <div class="img-card" v-for="item in goodsList" :key="item.id">
              <v-card elevation="0" style="background-color: #f8f3eb" @click="searchById(item.id)">
                <img :src="item.imgUrl" alt="" />
              </v-card>
              <p class="card-title">{{ item.title }}</p>
              <div v-if="item.price == 1" class="price-title"> Price on Request｜询价 </div>
              <div v-else>
                <div class="price-title"> Reference Price｜参考价 </div>
                <span>￥</span><span class="price">{{ item.price }}</span><span>.00</span>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="exhibitions artifacts">
          <TitleBar title="Artifacts丨器物" href="artifacts" :DataType="2"></TitleBar>
          <div class="card">
            <div class="img-card" v-for="item in goodsList2" :key="item.id">
              <v-card :loading="loading" elevation="0" style="background-color: #f8f3eb" @click="searchById(item.id)">
                <img :src="item.imgUrl" />
              </v-card>
              <p class="card-title">{{ item.title }}</p>
              <div v-if="item.price == 1" class="price-title"> Price on Request｜询价 </div>
              <div v-else>
                <div class="price-title"> Reference Price｜参考价 </div>
                <span>￥</span><span class="price">{{ item.price }}</span><span>.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-mobile" v-else>
        <div class="m-title">
          <h2>
            為一 Art Space是一间以日本浮世绘版画为主题，融汇了浮世绘展厅、日本工艺美术精品和咖啡业态的日本美学展示空间。
          </h2>
          <br />
          <h2 class="en">
            Weiyi Art Space is a Japanese aesthetic exhibition space with the theme of Japanese Ukiyo-e prints, which integrates the Ukiyo-e exhibition, Japanese arts , crafts and a coffee corner.
          </h2>
        </div>
        <v-divider></v-divider>
        <div class="e">
          <MTitleBar title="Exhibitions丨展览" href="./exhibitions"></MTitleBar>
          <img :src="disPaly.imgUrl" alt="" class="e-img" />
          <h2>{{ disPaly.title }}</h2>
          <h1 class="desc">

            {{disPaly.content}}
          </h1>
          <!-- <h1 class="desc">
            欢迎来到Egenolf画廊日本版画，自1975年以来一直提供优质的浮世绘和shin
            hanga。现在位于南加州，我们的 18 至 20
            世纪艺术品以美观、良好状态为策展，并保证原创且如描述。我们最近从日本回来，带来了一批了不起的新收购;作品每天发布，我们通过联邦快递以牢不可破的包装发货。
          </h1> -->
          <v-divider></v-divider>
        </div>
        <div class="good divider">
          <MTitleBar title="Gallery丨画廊" href="./gallery"></MTitleBar>
          <GoodCard :list="mGoodList"></GoodCard>
        </div>

        <div class="good">
          <MTitleBar title="Artifacts丨器物" href="./artifacts"></MTitleBar>
          <GoodCard :list="mGoodList2"></GoodCard>
        </div>
      </div>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "./components/layout/AppHeader";
import Footer from "./components/layout/AppFooter";
import TitleBar from "./components/TitleBar/index";
import MTitleBar from "./components/TitleBar/mobile";
import GoodCard from "./components/goodCard/mobile";
import { getGoodList } from "@/api/home";

export default {
  name: "App",
  components: { Header, Footer, TitleBar, MTitleBar, GoodCard },
  data() {
    return {
      disPaly: {},
      goodsList: [],
      goodsList2: [],
      mGoodList: [],
      mGoodList2: [],
      loading: false,
    };
  },
  methods: {
    searchExById(id) {
      location.assign("./disPlayDetail.html?id=" + id)
    },
    searchById(id) {
      location.assign("./detail.html?id=" + id);
    },
    getList() {
      this.loading = true;
      getGoodList().then((res) => {
        this.disPaly = res.data.data.displayList[0];
        this.disPaly.content = this.disPaly.content.replace(/<\/?.+?>/gi, "");
        this.mGoodList = res.data.data.goodsList;
        this.mGoodList2 = res.data.data.goodsList2;
        this.thousands(this.mGoodList);
        this.thousands(this.mGoodList2);
        this.goodsList = this.mGoodList.slice(0, 3);
        this.goodsList2 = this.mGoodList2.slice(0, 3);
        this.loading = false;
      });
      console.log(this.goodsList);
    },
    thousands(goodList) {
      goodList.map((item) => {
        let str = item.price.toString();
        let reg =
          str.indexOf(".") > -1
            ? /(\d)(?=(\d{3})+\.)/g
            : /(\d)(?=(?:\d{3})+$)/g;
        item.price = str.replace(reg, "$1,");
      });
    },
  },
  mounted() {
    this.getList();
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
  },
};
</script>
<style lang="scss" scope>
.v-main {
  background-color: #f8f3eb;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    margin: 0 auto;

    .title {
      width: 1200px;
      margin-top: 70px;

      h2 {
        display: block;
        font-size: 15px;
        /*no*/
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #333333;
        line-height: normal;
      }

      .jp {
        display: block;
        margin-top: 30px;
        color: #999999;
        margin-bottom: 25px;
        font-weight: 400;
        line-height: normal;
        font-family: SF-Pro-Display-Regular;
      }
    }

    .exhibitions {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 31px;

      .exhibitions-img {
        width: 600px !important;
        height: 360px !important;
        margin-right: 32px !important;
        cursor: pointer;
      }

      .bar {
        width: 100%;
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .more {
          cursor: pointer;
          height: 100%;

          a {
            display: flex;
            height: 100%;
            color: #333333;
            align-items: center;
            justify-content: space-between;

            p {
              width: 40px;
              height: 29px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              margin: 0 8px 0 0;
            }

            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .card {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 32px;
        // margin-right: 60px;

        img {
          width: 360px;
          height: 505px;
          object-fit: cover;
        }

        &:nth-child(3n) {
          img {
            margin-right: 0px !important;
          }
        }

        .card-title {
          width: 360px;
          height: 50px;
          margin-top: 16px;
          margin-bottom: 24px;
          font-size: 15px;
          font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
          font-weight: 600;;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-word;
        }

        .price-title{
          font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
        }

        .content {
          width: 90%;
          height: 260px;
          // display: flex;
          flex-wrap: wrap;
          // margin-top: 25px;
          // max-height: 300px;
          padding-top: 50px;
          position: relative;
          align-items: center;
          color: #333333;
          overflow: hidden;
          // display: -webkit-box;
          // -webkit-line-clamp: 6;
          // -webkit-box-orient: vertical;


          .content-p {
            // flex: 1;
            // width: 750px;
            height: 150px;
            overflow: hidden;
            display: block;
            font-size: 15px;
            line-height: 30px;
            /*no*/
            // position: relative;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333;
            margin-bottom: 8px;
            margin-top: 16px;
            overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 5;
            // -webkit-box-orient: vertical;

            &::after{
              content: "...";
              position: absolute;
              height: 30px;
              bottom: -5px;
              right: 0;
              padding-right: 345px;
              padding-left: 5px;
              background: linear-gradient(to right, transparent, #f8f3eb 0%);
            }
          }
        }

        span {
          font-size: 15px;
          /*no*/
        }

        .price {
          font-size: 24px;
          /*no*/
        }
      }
    }
  }

  .index-mobile {
    width: calc(343 * 5.12px);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .m-title {
      margin-top: calc(14 * 5.12px);

      h2 {
        font-size: calc(14 * 5.12px);
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #333333;
        line-height: calc(28 * 5.12px);
      }

      .en {
        color: #999999;
        margin-bottom: calc(24 * 5.12px);
        font-family: SF-Pro-Display-Regular;
      }
    }

    .e{
      h2{
        text-align: center;
        font-size: calc(13 * 5.12px);
      }
    }

    .e-img {
      width: calc(343 * 5.12px);
      height: calc(206 * 5.12px);
      object-fit: cover;
      margin-bottom: calc(16 * 5.12px);
    }

    .desc {
      height: calc(174 * 5.12px);
      font-size: calc(14 * 5.12px);
      font-family: Microsoft YaHei;
      font-weight: 500;
      color: #333333;
      line-height: calc(28 * 5.12px);
      margin-bottom: calc(24 * 5.12px);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }

    .good {
      width: calc(343 * 5.12px);
      display: flex;
      flex-wrap: wrap;

    }

    .divider {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.12);
    }
  }
}
</style>